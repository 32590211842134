// src/components/AuthAction.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { auth } from '../firebase';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import styles from './AuthAction.module.css'

const AuthAction = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const mode = query.get('mode');
  const actionCode = query.get('oobCode');

  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const verifyCode = async () => {
      try {
        if (mode === 'resetPassword') {
          await verifyPasswordResetCode(auth, actionCode);
        }
      } catch (error) {
        setMessage('Invalid or expired action code. Please try again.');
      }
    };
    verifyCode();
  }, [mode, actionCode]);

  const handlePasswordReset = async () => {
    try {
      await confirmPasswordReset(auth, actionCode, newPassword);
      setMessage('Password has been reset successfully.');
    } catch (error) {
      setMessage('Error resetting password: ' + error.message);
    }
  };

  return (
    <div className={styles.container}>
      {mode === 'resetPassword' && (
        <div className={styles.formWrapper}>
          <h2>Reset your password</h2>
          <div className={styles.formGroup}>
            <input
              type="password"
              placeholder="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <button onClick={handlePasswordReset} className={styles.btnPrimary}>Save</button>
          {message && <p className={styles.message}>{message}</p>}
        </div>
      )}
    </div>
  );
};

export default AuthAction;
