import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, addDoc, updateDoc, deleteDoc, doc, getDocs, query, where, writeBatch } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addCategory, getCategories, deleteCategory } from '../services/firestoreService';
import Modal from 'react-modal';

const AdminProducts = () => {
  const [products, setProducts] = useState([]);
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productStock, setProductStock] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [productImage, setProductImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [showCategories, setShowCategories] = useState(false);
  const [editProduct, setEditProduct] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const firestore = getFirestore();
  const storage = getStorage();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchProductsAndCategories = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'products'));
      const productsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsList);

      const categoriesSnapshot = await getCategories();
      const categoriesList = categoriesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCategories(categoriesList);
    };

    fetchProductsAndCategories();
  }, [firestore]);

  const handleAddProduct = async () => {
    let category = productCategory;

    if (category === 'Add New Category' && newCategory) {
      const newCategoryDoc = await addCategory(newCategory);
      category = newCategory;
      setCategories([...categories, { id: newCategoryDoc.id, name: newCategory }]);
      setNewCategory('');
    }

    if (productName && productPrice && productStock && category && productImage) {
      const storageRef = ref(storage, `products/${productImage.name}`);
      await uploadBytes(storageRef, productImage);
      const imageUrl = await getDownloadURL(storageRef);

      await addDoc(collection(firestore, 'products'), {
        name: productName,
        price: parseFloat(productPrice),
        stock: parseInt(productStock, 10),
        category: category,
        description: productDescription,
        imageUrl: imageUrl,
        orderCount: 0, // New field for best-selling products
        createdAt: new Date(), // New field for recently added products
        disabled: false
      });

      setProductName('');
      setProductPrice('');
      setProductStock('');
      setProductCategory('');
      setProductDescription('');
      setProductImage(null);
      fileInputRef.current.value = null;

      alert('Product added successfully!');

      // Refresh products list
      const querySnapshot = await getDocs(collection(firestore, 'products'));
      const productsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsList);
    }
  };

  const openModal = (product) => {
    setEditProduct(product);
    setProductName(product.name);
    setProductPrice(product.price);
    setProductStock(product.stock);
    setProductCategory(product.category);
    setProductDescription(product.description);
    setProductImage(null);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setEditProduct(null);
    setProductName('');
    setProductPrice('');
    setProductStock('');
    setProductCategory('');
    setProductDescription('');
    setProductImage(null);
    fileInputRef.current.value = null;
    setModalIsOpen(false);
  };

  const handleUpdateProduct = async () => {
    if (editProduct) {
      const productRef = doc(firestore, 'products', editProduct.id);
      let imageUrl = editProduct.imageUrl;

      if (productImage) {
        const storageRef = ref(storage, `products/${productImage.name}`);
        await uploadBytes(storageRef, productImage);
        imageUrl = await getDownloadURL(storageRef);
      }

      await updateDoc(productRef, {
        name: productName,
        price: parseFloat(productPrice),
        stock: parseInt(productStock, 10),
        category: productCategory,
        description: productDescription,
        imageUrl: imageUrl
      });

      alert('Product updated successfully!');

      // Refresh products list
      const querySnapshot = await getDocs(collection(firestore, 'products'));
      const productsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsList);

      closeModal();
    }
  };

  const handleDeleteProduct = async (id) => {
    await deleteDoc(doc(firestore, 'products', id));
    alert('Product deleted successfully!');
    // Refresh products list
    const querySnapshot = await getDocs(collection(firestore, 'products'));
    const productsList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setProducts(productsList);
  };

  const handleDisableProduct = async (id, disabled) => {
    await updateDoc(doc(firestore, 'products', id), { disabled });
    // Refresh products list
    const querySnapshot = await getDocs(collection(firestore, 'products'));
    const productsList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setProducts(productsList);
  };

  const handleDisableCategory = async (id, disabled) => {
    const categoryRef = doc(firestore, 'categories', id);
    await updateDoc(categoryRef, { disabled });

    const productsQuery = query(collection(firestore, 'products'), where('category', '==', categories.find(c => c.id === id).name));
    const productsSnapshot = await getDocs(productsQuery);
    const batch = writeBatch(firestore);

    productsSnapshot.forEach((productDoc) => {
      const productRef = doc(firestore, 'products', productDoc.id);
      batch.update(productRef, { disabled });
    });

    await batch.commit();

    alert(`Category and its products ${disabled ? 'disabled' : 'enabled'} successfully!`);

    // Refresh categories and products lists
    const categoriesSnapshot = await getCategories();
    setCategories(categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

    const querySnapshot = await getDocs(collection(firestore, 'products'));
    setProducts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleDeleteCategory = async (id) => {
    await deleteCategory(id);
    setCategories(categories.filter(category => category.id !== id));
    alert('Category deleted successfully!');
  };

  return (
    <div>
      <h2>Manage Products</h2>

      <button onClick={() => setShowCategories(!showCategories)}>
        {showCategories ? 'Hide Categories' : 'Show Categories'}
      </button>

      {showCategories && (
        <div>
          <h3>Manage Categories:</h3>
          <ul>
            {categories.map((category) => (
              <li key={category.id}>
                {category.name}
                <button onClick={() => handleDisableCategory(category.id, !category.disabled)}>
                  {category.disabled ? 'Enable' : 'Disable'}
                </button>
                <button onClick={() => handleDeleteCategory(category.id)}>Delete</button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div>
        <input
          type="text"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          placeholder="Product Name"
        />
        <input
          type="number"
          value={productPrice}
          onChange={(e) => setProductPrice(e.target.value)}
          placeholder="Product Price"
        />
        <input
          type="number"
          value={productStock}
          onChange={(e) => setProductStock(e.target.value)}
          placeholder="Product Stock"
        />
        <select
          value={productCategory}
          onChange={(e) => setProductCategory(e.target.value)}
        >
          <option value="">Select Category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.name}>{category.name}</option>
          ))}
          <option value="Add New Category">Add New Category</option>
        </select>
        {productCategory === 'Add New Category' && (
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="New Category Name"
          />
        )}
        <input
          type="file"
          onChange={(e) => setProductImage(e.target.files[0])}
          ref={fileInputRef}
        />
        <textarea
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
          placeholder="Product Description"
        />
        <button onClick={handleAddProduct}>Add Product</button>
      </div>

      <h3>Current Products:</h3>
      <ul>
        {products.map((product) => (
          <li key={product.id}>
            <div>
              <img src={product.imageUrl} alt={product.name} style={{ width: '100px', height: '100px' }} />
            </div>
            {product.name} - ${product.price} - Stock: {product.stock} - {product.disabled ? 'Disabled' : 'Enabled'}
            <button onClick={() => openModal(product)}>Edit</button>
            <button onClick={() => handleDisableProduct(product.id, !product.disabled)}>
              {product.disabled ? 'Enable' : 'Disable'}
            </button>
            <button onClick={() => handleDeleteProduct(product.id)}>Delete</button>
          </li>
        ))}
      </ul>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Product"
      >
        <h2>Edit Product</h2>
        <form>
          <input
            type="text"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            placeholder="Product Name"
          />
          <input
            type="number"
            value={productPrice}
            onChange={(e) => setProductPrice(e.target.value)}
            placeholder="Product Price"
          />
          <input
            type="number"
            value={productStock}
            onChange={(e) => setProductStock(e.target.value)}
            placeholder="Product Stock"
          />
          <select
            value={productCategory}
            onChange={(e) => setProductCategory(e.target.value)}
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.name}>{category.name}</option>
            ))}
            <option value="Add New Category">Add New Category</option>
          </select>
          {productCategory === 'Add New Category' && (
            <input
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder="New Category Name"
            />
          )}
          <input
            type="file"
            onChange={(e) => setProductImage(e.target.files[0])}
            ref={fileInputRef}
          />
          <textarea
            value={productDescription}
            onChange={(e) => setProductDescription(e.target.value)}
            placeholder="Product Description"
          />
          <button type="button" onClick={handleUpdateProduct}>Update Product</button>
        </form>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default AdminProducts;
