import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { loadCart, updateProductStock, saveCart, saveOrder } from '../services/firestoreService';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import Modal from 'react-modal';
import styles from './Checkout.module.css';  // Import CSS module

const Checkout = () => {
  const [cart, setCart] = useState([]);
  const [recipientEmail, setRecipientEmail] = useState(auth.currentUser ? auth.currentUser.email : '');
  const [shippingMethod, setShippingMethod] = useState('delivery');
  const [shippingInfo, setShippingInfo] = useState({
    recipientName: '',
    recipientLastName: '',
    direccion: '',
    provincia: '',
    canton: '',
    distrito: '',
    codigoPostal: '',
    pais: 'Costa Rica',
    telefono: ''
  });
  const [billingInfo, setBillingInfo] = useState({
    recipientName: '',
    recipientLastName: '',
    direccion: '',
    provincia: '',
    canton: '',
    distrito: '',
    codigoPostal: '',
    pais: 'Costa Rica',
    telefono: ''
  });
  const [useShippingAsBilling, setUseShippingAsBilling] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [saveShippingInfo, setSaveShippingInfo] = useState(false);
  const [isPaymentProcessed, setIsPaymentProcessed] = useState(false);
  const navigate = useNavigate();
  const { setCart: setCartContext } = useCart();

  useEffect(() => {
    const fetchCartAndUserData = async () => {
      if (auth.currentUser) {
        const cartData = await loadCart();
        setCart(cartData);

        const userDoc = await getDoc(doc(firestore, 'users', auth.currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setShippingInfo((prevInfo) => ({
            ...prevInfo,
            recipientName: data.name || '',
            recipientLastName: data.lastName || '',
            direccion: data.direccion || '',
            provincia: data.provincia || '',
            canton: data.canton || '',
            distrito: data.distrito || '',
            codigoPostal: data.codigoPostal || '',
            pais: 'Costa Rica',
            telefono: data.telefono || ''
          }));
          setRecipientEmail(auth.currentUser.email);
          setBillingInfo((prevInfo) => ({
            ...prevInfo,
            recipientName: data.name || '',
            recipientLastName: data.lastName || '',
            direccion: data.direccion || '',
            provincia: data.provincia || '',
            canton: data.canton || '',
            distrito: data.distrito || '',
            codigoPostal: data.codigoPostal || '',
            pais: 'Costa Rica',
            telefono: data.telefono || ''
          }));
        }
      } else {
        const savedCart = localStorage.getItem('cart');
        setCart(savedCart ? JSON.parse(savedCart) : []);
      }
    };

    fetchCartAndUserData();
  }, []);

  const handleInputChange = (e, isBilling = false) => {
    const { name, value } = e.target;
    if (isBilling) {
      setBillingInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
    } else {
      setShippingInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
      if (useShippingAsBilling) {
        setBillingInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
      }
    }
  };

  const handleEmailChange = (e) => {
    setRecipientEmail(e.target.value);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleSaveShippingInfoChange = (e) => {
    setSaveShippingInfo(e.target.checked);
  };

  const handleBillingOptionChange = (e) => {
    const useShipping = e.target.value === 'same';
    setUseShippingAsBilling(useShipping);
    if (useShipping) {
      setBillingInfo(shippingInfo);
    }
  };

  const handleShippingMethodChange = (e) => {
    setShippingMethod(e.target.value);
  };

  const validateShippingInfo = () => {
    const {
      recipientName,
      recipientLastName,
      direccion,
      provincia,
      canton,
      distrito,
      pais,
    } = shippingInfo;

    return (
      recipientName &&
      recipientLastName &&
      direccion &&
      provincia &&
      canton &&
      distrito &&
      pais
    );
  };

  const validateBillingInfo = () => {
    const {
      recipientName,
      recipientLastName,
      direccion,
      provincia,
      canton,
      distrito,
      pais,
    } = billingInfo;

    return (
      recipientName &&
      recipientLastName &&
      direccion &&
      provincia &&
      canton &&
      distrito &&
      pais
    );
  };

  const handleCheckout = async () => {
    if (!recipientEmail) {
      alert('Recipient Email is required.');
      return;
    }

    if (shippingMethod === 'delivery' && !validateShippingInfo()) {
      alert('All fields except Postal Code and Phone are mandatory in the Shipping Information.');
      return;
    }

    if (!validateBillingInfo()) {
      alert('All fields except Postal Code and Phone are mandatory in the Billing Information.');
      return;
    }

    if (!paymentMethod) {
      alert('Please select a payment method.');
      return;
    }

    try {
      let isStockAvailable = true;
      const updatedCart = [];

      for (const item of cart) {
        const newStock = await updateProductStock(item.id, item.quantity);
        if (newStock < 0) {
          isStockAvailable = false;
          updatedCart.push({ ...item, outOfStock: true });
        } else {
          updatedCart.push({ ...item, outOfStock: false });
        }
      }

      if (isStockAvailable) {
        const order = {
          userId: auth.currentUser ? auth.currentUser.uid : 'guest',
          email: recipientEmail,
          items: updatedCart,
          total: updatedCart.reduce((acc, item) => (item.outOfStock ? acc : acc + item.price * item.quantity), 0),
          shippingMethod,
          shippingInfo: shippingMethod === 'delivery' ? shippingInfo : null,
          billingInfo: useShippingAsBilling ? shippingInfo : billingInfo,
          paymentMethod,
          date: new Date().toISOString(),
        };

        await saveOrder(order);

        if (saveShippingInfo && auth.currentUser && shippingMethod === 'delivery') {
          const userDocRef = doc(firestore, 'users', auth.currentUser.uid);
          await updateDoc(userDocRef, { ...shippingInfo });
        }

        if (paymentMethod === 'creditCard') {
          alert('Soon Available.');
        } else {
          alert(`Please follow the instructions for ${paymentMethod}.`);
        }

        setIsPaymentProcessed(true);

        setCart([]);
        setCartContext([]);
        localStorage.removeItem('cart');
        if (auth.currentUser) {
          await saveCart([]);
        }
      } else {
        alert('Some items in your cart are out of stock. Please review your cart.');
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        if (auth.currentUser) {
          await saveCart(updatedCart);
        }
      }
    } catch (err) {
      console.error('Failed to update stock:', err);
    }
  };

  const handleCloseModal = async () => {
    setIsPaymentProcessed(false);
    navigate('/confirmation');
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  return (
    <div className={styles.checkoutContainer}>
      <h2 className={styles.title}>Checkout</h2>
      <div className={`form-group ${styles.formGroup}`}>
      <h3 className={styles.sectionTitle}>Recipient Email</h3>
        <input
          type="email"
          value={recipientEmail}
          onChange={handleEmailChange}
          required
          className={styles.formControl}
        />
      </div>
      <div className="mb-4">
        <h3 className={styles.sectionTitle}>Shipping Method</h3>
        <div className={styles.shippingMethodGroup}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="shippingMethod"
              id="pickup"
              value="pickup"
              checked={shippingMethod === 'pickup'}
              onChange={handleShippingMethodChange}
            />
            <label className="form-check-label" htmlFor="pickup">
              Pickup at Store
            </label> 
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="shippingMethod"
              id="delivery"
              value="delivery"
              checked={shippingMethod === 'delivery'}
              onChange={handleShippingMethodChange}
            />
            <label className="form-check-label" htmlFor="delivery">
              Delivery
            </label>
          </div>
        </div>

        {shippingMethod === 'pickup' && (
          <div className={`alert alert-info mt-3 ${styles.pickupInfo}`}>
            <p>Please visit our store for pickup. We are open from 9:00 AM to 6:00 PM, Monday through Saturday.</p>
          </div>
        )}

        {shippingMethod === 'delivery' && (
          <form>
            <h4 className={styles.sectionTitle}>Delivery Information</h4>
            <div className="form-group">
              <input
                type="text"
                name="recipientName"
                value={shippingInfo.recipientName}
                onChange={(e) => handleInputChange(e)}
                placeholder="Recipient's First Name"
                required
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="recipientLastName"
                value={shippingInfo.recipientLastName}
                onChange={(e) => handleInputChange(e)}
                placeholder="Recipient's Last Name"
                required
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="direccion"
                value={shippingInfo.direccion}
                onChange={(e) => handleInputChange(e)}
                placeholder="Address"
                required
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="provincia"
                value={shippingInfo.provincia}
                onChange={(e) => handleInputChange(e)}
                placeholder="Province"
                required
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="canton"
                value={shippingInfo.canton}
                onChange={(e) => handleInputChange(e)}
                placeholder="Canton"
                required
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="distrito"
                value={shippingInfo.distrito}
                onChange={(e) => handleInputChange(e)}
                placeholder="District"
                required
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="codigoPostal"
                value={shippingInfo.codigoPostal}
                onChange={(e) => handleInputChange(e)}
                placeholder="Postal Code (Optional)"
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="pais"
                value={shippingInfo.pais}
                onChange={(e) => handleInputChange(e)}
                placeholder="Country"
                required
                className={styles.formControl}
                readOnly
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="telefono"
                value={shippingInfo.telefono}
                onChange={(e) => handleInputChange(e)}
                placeholder="Phone (Optional)"
                className={styles.formControl}
              />
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="saveShippingInfo"
                checked={saveShippingInfo}
                onChange={handleSaveShippingInfoChange}
              />
              <label className="form-check-label" htmlFor="saveShippingInfo">Save this information for future purchases</label>
            </div>
          </form>
        )}
      </div>

      <div className="mb-4">
        <h3 className={styles.sectionTitle}>Billing Information</h3>
        <div className={styles.billingOptionGroup}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="billingOption"
              id="sameAsShipping"
              value="same"
              checked={useShippingAsBilling}
              onChange={handleBillingOptionChange}
            />
            <label className="form-check-label" htmlFor="sameAsShipping">
              Use the same address as shipping
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="billingOption"
              id="differentBilling"
              value="different"
              checked={!useShippingAsBilling}
              onChange={handleBillingOptionChange}
            />
            <label className="form-check-label" htmlFor="differentBilling">
              Use a different billing address
            </label>
          </div>
        </div>

        {!useShippingAsBilling && (
          <form>
            <div className="form-group">
              <input
                type="text"
                name="recipientName"
                value={billingInfo.recipientName}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Billing Recipient's First Name"
                required
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="recipientLastName"
                value={billingInfo.recipientLastName}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Billing Recipient's Last Name"
                required
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="direccion"
                value={billingInfo.direccion}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Billing Address"
                required
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="provincia"
                value={billingInfo.provincia}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Billing Province"
                required
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="canton"
                value={billingInfo.canton}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Billing Canton"
                required
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="distrito"
                value={billingInfo.distrito}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Billing District"
                required
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="codigoPostal"
                value={billingInfo.codigoPostal}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Billing Postal Code (Optional)"
                className={styles.formControl}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="pais"
                value={billingInfo.pais}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Billing Country"
                required
                className={styles.formControl}
                readOnly
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="telefono"
                value={billingInfo.telefono}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Billing Phone (Optional)"
                className={styles.formControl}
              />
            </div>
          </form>
        )}
      </div>

      <div className="mb-4">
        <h3 className={styles.sectionTitle}>Payment Method</h3>
        <div className={styles.paymentMethodGroup}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="paymentMethod"
              id="creditCard"
              value="creditCard"
              onChange={handlePaymentMethodChange}
            />
            <label className="form-check-label" htmlFor="creditCard">
              Payment by Credit Card (Soon Available)
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="paymentMethod"
              id="sinpeMobile"
              value="sinpeMobile"
              onChange={handlePaymentMethodChange}
            />
            <label className="form-check-label" htmlFor="sinpeMobile">
              SINPE Mobile
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="paymentMethod"
              id="bankTransfer"
              value="bankTransfer"
              onChange={handlePaymentMethodChange}
            />
            <label className="form-check-label" htmlFor="bankTransfer">
              Bank Transfer
            </label>
          </div>
        </div>

        {paymentMethod === 'sinpeMobile' && (
          <div className={`alert alert-info mt-3 ${styles.paymentInfo}`}>
            <p>Please send the SINPE Mobile payment receipt via WhatsApp to the number <strong>+506 6403 7952</strong>.</p>
          </div>
        )}

        {paymentMethod === 'bankTransfer' && (
          <div className={`alert alert-info mt-3 ${styles.paymentInfo}`}>
            <p>Please send the Bank Transfer receipt via WhatsApp to the number <strong>+506 6403 7952</strong>.</p>
          </div>
        )}
      </div>

      <div className="mb-4">
        <h3 className={styles.sectionTitle}>Order Summary</h3>
        <ul className={`list-group ${styles.orderSummaryGroup}`}>
          {cart.map((item, index) => (
            <li key={index} className={`list-group-item ${item.outOfStock ? 'text-muted' : ''} ${styles.listGroupItem}`}>
              {item.name} - {formatCurrency(item.price)} x {item.quantity} {item.outOfStock && '(Out of Stock)'}
            </li>
          ))}
        </ul>
        <h4 className={styles.totalTitle}>Total: {formatCurrency(cart.reduce((acc, item) => (item.outOfStock ? acc : acc + item.price * item.quantity), 0))}</h4>
      </div>
      <button onClick={handleCheckout} className={`btn btn-primary ${styles.checkoutBtn}`}>Proceed to Payment</button>

      <Modal
        isOpen={isPaymentProcessed}
        onRequestClose={handleCloseModal}
        contentLabel="Payment Processed"
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}  // Add this line

      >
        <h2>Payment Processed Successfully!</h2>
        <button onClick={handleCloseModal} className="btn btn-primary">Close</button>
      </Modal>
    </div>
  );
};

export default Checkout;
