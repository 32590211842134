import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { getProducts, getCategories } from '../services/firestoreService';
import { useCart } from '../contexts/CartContext';
import styles from './ProductCatalog.module.css'; // Import CSS module
import { useSpring, animated } from '@react-spring/web'; // Import React Spring

const ProductCatalog = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState('');
  const [sort, setSort] = useState('');
  const { addToCart } = useCart();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const search = query.get('search');
    if (search) {
      setSearchTerm(search);
    }

    const fetchProducts = async () => {
      try {
        const productsSnapshot = await getProducts();
        const productsList = productsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(product => !product.disabled);
        setProducts(productsList);
      } catch (err) {
        console.error('Failed to fetch products:', err);
      }
    };

    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getCategories();
        const categoriesList = categoriesSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(category => !category.disabled);
        setCategories(categoriesList);
      } catch (err) {
        console.error('Failed to fetch categories:', err);
      }
    };

    fetchProducts();
    fetchCategories();
  }, [location.search]);

  const handleSortChange = (e) => {
    const { value } = e.target;
    setSort(value);
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setCategory(value);
  };

  const filteredProducts = products
    .filter(product => 
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.category.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(product => (category ? product.category === category : true))
    .sort((a, b) => {
      if (sort === 'price-asc') return a.price - b.price;
      if (sort === 'price-desc') return b.price - a.price;
      if (sort === 'popularity') return b.popularity - a.popularity;
      return 0;
    });

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  // Animation for search bar, category, and sort selects
  const searchBarAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  });

  return (
    <div className="container">
      <h2 className="my-4">Product Catalog</h2>
      <animated.div style={searchBarAnimation} className={styles.searchContainer}>
        <input
          type="text"
          className={`form-control ${styles.searchBar}`}
          placeholder="Search products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select className={`form-control mt-2 ${styles.categorySelect}`} value={category} onChange={handleCategoryChange}>
          <option value="">All Categories</option>
          {categories.map(cat => (
            <option key={cat.id} value={cat.name}>{cat.name}</option>
          ))}
        </select>
        <select className={`form-control mt-2 ${styles.sortSelect}`} value={sort} onChange={handleSortChange}>
          <option value="">Sort By</option>
          <option value="price-asc">Price: Low to High</option>
          <option value="price-desc">Price: High to Low</option>
          <option value="popularity">Popularity</option>
        </select>
      </animated.div>
      <div className="row">
        {filteredProducts.map(product => (
          <div key={product.id} className="col-md-4 d-flex">
            <div className={`card mb-4 ${styles.card}`}>
              <div className={styles['card-img-container']}>
                <img src={product.imageUrl} alt={product.name} className={`card-img-top ${styles.cardImgTop}`} />
              </div>
              <div className={`card-body ${styles.cardBody}`}>
                <h5 className={`card-title ${styles.cardTitle}`}>{product.name}</h5>
                <p className={`card-text ${styles.cardText}`}>{product.description}</p>
                <p className={`card-text ${styles.productPrice}`}><strong>{formatCurrency(product.price)}</strong></p>
                <p className={`card-text ${styles.productCategory}`}>Category: {product.category}</p>
                <p className={`card-text ${styles.productStock}`}>Stock: {product.stock > 0 ? product.stock : 'Out of Stock'}</p>
                <button onClick={() => addToCart(product)} className={`btn btn-primary ${styles.productButton}`} disabled={product.stock <= 0}>
                  {product.stock > 0 ? 'Add to Cart' : 'Out of Stock'}
                </button>
                <Link to={`/products/${product.id}`}>
                  <button className={`btn btn-secondary mt-2 ${styles.productButton}`}>View Product</button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductCatalog;
