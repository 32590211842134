import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { useCart } from '../contexts/CartContext';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Container } from 'react-bootstrap';
import Modal from 'react-modal';
import styles from './NavBar.module.css'; // Import CSS module
import logo from '../images/logonavbar.png'; // Import the logo image

Modal.setAppElement('#root');

const NavBar = ({ user, userName, toggleCartSidebar, setIsCartSidebarOpen }) => {
  const navigate = useNavigate();
  const { cart } = useCart();
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        const firestore = getFirestore();
        const q = query(collection(firestore, 'admins'), where('email', '==', user.email));
        const querySnapshot = await getDocs(q);
        setIsAdmin(!querySnapshot.empty);
      }
    };

    checkAdmin();
  }, [user]);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setIsCartSidebarOpen(false);
      navigate('/');
    } catch (err) {
      console.error('Failed to sign out:', err);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/products?search=${searchQuery}`);
    }
  };

  return (
    <Navbar bg="light" expand="lg" className={styles.navbar}>
      <Container>
        <Navbar.Brand as={Link} to="/" className={styles.navbarBrand}>
          <img src={logo} alt="Floristeria Armony" className={styles.navbarLogo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {user && (
              <Nav.Link className={styles.navLink}>Welcome, {userName}</Nav.Link>
            )}
            <Nav.Link as={Link} to="/products" className={styles.navLink}>Products</Nav.Link>
            {user ? (
              <>
                <Nav.Link as={Link} to="/customer/profile" className={styles.navLink}>Profile</Nav.Link>
                <Nav.Link as={Link} to="/wishlist" className={styles.navLink}>Wishlist</Nav.Link>
                <Nav.Link onClick={toggleCartSidebar} className={styles.navLink}>
                  Cart ({cart.length})
                </Nav.Link>
                {isAdmin && (
                  <NavDropdown title="Admin" id="admin-dropdown">
                    <NavDropdown.Item as={Link} to="/admin/products">Manage Products</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/management">Manage Admins</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/homepage-content">Manage Homepage</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/popup-management">Manage Popups</NavDropdown.Item>
                  </NavDropdown>
                )}
                <Nav.Link onClick={handleSignOut} className={styles.navLink}>Sign Out</Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link onClick={toggleCartSidebar} className={styles.navLink}>
                  Cart ({cart.length}) {/* Display cart count for non-logged-in users */}
                </Nav.Link>
                <Nav.Link as={Link} to="/customer/login" className={styles.navLink}>Login</Nav.Link>
                <Nav.Link as={Link} to="/customer/register" className={styles.navLink}>Register</Nav.Link>
              </>
            )}
          </Nav>
          <Form className="d-flex ml-auto" onSubmit={handleSearch}>
            <FormControl
              type="search"
              placeholder="Search"
              className={`${styles.formControl} mr-2`}
              aria-label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button variant="outline-success" type="submit" className={styles.btnOutlineSuccess}>Search</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
