import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import styles from './CartSidebar.module.css'; // Import CSS module

const CartSidebar = ({ isOpen, toggleCartSidebar }) => {
  const { cart, updateQuantity, removeFromCart } = useCart();

  const handleQuantityChange = (id, newQuantity) => {
    updateQuantity(id, newQuantity);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  return (
    <div className={`${styles.cartSidebar} ${isOpen ? styles.open : ''}`}>
      <div className={styles.cartTitle}>
        Shopping Cart
        <button onClick={toggleCartSidebar} className={styles.closeButton}>×</button>
      </div>
      <ul className="list-group">
        {cart.map((item, index) => (
          <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
            <div className={styles.cartItemDetails}>
              <img src={item.imageUrl} alt={item.name} className={styles.cartItemImage} />
              <div>
                <p>{item.name}</p>
                <p>{formatCurrency(item.price)}</p>
              </div>
            </div>
            <div>
              <input
                type="number"
                value={item.quantity}
                min="1"
                max={item.stock}
                onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value, 10))}
                className={`form-control ${styles.quantityInput}`}
              />
              <button onClick={() => removeFromCart(item.id)} className={styles.removeButton}>Remove</button>
            </div>
          </li>
        ))}
      </ul>
      {cart.length > 0 && (
        <Link to="/checkout">
          <button className={styles.proceedButton}>Proceed to Checkout</button>
        </Link>
      )}
    </div>
  );
};

export default CartSidebar;
