import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getProduct, addReview, getReviews, loadOrderHistory } from '../services/firestoreService';
import ReactStars from 'react-rating-stars-component';
import { auth, firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useCart } from '../contexts/CartContext';

const ProductDetails = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({ rating: 0, text: '' });
  const [user, setUser] = useState(null);
  const [hasPurchased, setHasPurchased] = useState(false);
  const { addToCart } = useCart();

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productData = await getProduct(productId);
        setProduct({ ...productData, id: productId }); // Include the product ID here
      } catch (err) {
        console.error('Failed to fetch product:', err);
      }
    };

    const fetchReviews = async () => {
      try {
        const reviewsList = await getReviews(productId);
        setReviews(reviewsList);
      } catch (err) {
        console.error('Failed to fetch reviews:', err);
      }
    };

    const fetchOrderHistory = async (userId) => {
      try {
        const orders = await loadOrderHistory();
        const purchasedProducts = orders.flatMap(order => order.items.map(item => item.id));
        setHasPurchased(purchasedProducts.includes(productId));
      } catch (err) {
        console.error('Failed to fetch order history:', err);
      }
    };

    fetchProduct();
    fetchReviews();

    const unsubscribe = auth.onAuthStateChanged(async currentUser => {
      setUser(currentUser);
      if (currentUser) {
        await fetchOrderHistory(currentUser.uid);
      }
    });

    return () => unsubscribe();
  }, [productId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReview((prevReview) => ({ ...prevReview, [name]: value }));
  };

  const handleRatingChange = (newRating) => {
    setNewReview((prevReview) => ({ ...prevReview, rating: newRating }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      alert('You need to be logged in to add a review.');
      return;
    }

    try {
      const userDocRef = doc(firestore, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        console.error('User document does not exist');
        return;
      }

      const name = userDoc.data().name;
      if (!name) {
        console.error('User name is undefined in Firestore document');
        return;
      }

      const reviewToSubmit = {
        ...newReview,
        name: name,
      };

      await addReview(productId, reviewToSubmit);
      setReviews((prevReviews) => [...prevReviews, reviewToSubmit]);
      setNewReview({ rating: 0, text: '', name: '' });
    } catch (err) {
      console.error('Failed to get user name or add review:', err);
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <h2>{product.name}</h2>
      <img src={product.imageUrl} alt={product.name} className="img-fluid" />
      <p>{product.description}</p> {/* Display the description */}
      <p>Price: {formatCurrency(product.price)}</p>
      <p>Category: {product.category}</p>
      <p>Stock: {product.stock > 0 ? product.stock : 'Out of Stock'}</p>
      <button onClick={() => addToCart(product)} className="btn btn-primary" disabled={product.stock <= 0}>
        {product.stock > 0 ? 'Add to Cart' : 'Out of Stock'}
      </button>
      <h3>Reviews</h3>
      <ul className="list-group mb-4">
        {reviews.map((review, index) => (
          <li key={index} className="list-group-item">
            <strong>Rating: </strong>
            <ReactStars
              count={5}
              value={review.rating}
              edit={false}
              size={24}
              activeColor="#ffd700"
            />
            <br />
            <strong>Review: </strong>{review.text}
            <br />
            <strong>From: </strong>{review.name}
          </li>
        ))}
      </ul>
      {hasPurchased ? (
        <>
          <h3>Add a Review</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Rating</label>
              <ReactStars
                count={5}
                onChange={handleRatingChange}
                size={24}
                activeColor="#ffd700"
                value={newReview.rating}
              />
            </div>
            <div className="form-group">
              <label>Review</label>
              <textarea
                name="text"
                value={newReview.text}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary mt-2">Submit Review</button>
          </form>
        </>
      ) : (
        <p>You can only review products you have purchased.</p>
      )}
      <Link to="/customer/profile">
        <button className="btn btn-secondary mt-3">Go to Profile</button>
      </Link>
    </div>
  );
};

export default ProductDetails;
