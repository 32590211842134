// src/components/OrderHistory.js
import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { loadOrderHistory } from '../services/firestoreService';
import { Link } from 'react-router-dom';

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrderHistory = async () => {
      if (auth.currentUser) {
        try {
          const orderHistoryData = await loadOrderHistory();
          setOrders(orderHistoryData);
        } catch (err) {
          console.error('Failed to load order history:', err);
        }
      }
    };

    fetchOrderHistory();
  }, []);

  return (
    <div className="container">
      <h2 className="my-4">Order History</h2>
      {orders.length > 0 ? (
        <ul className="list-group">
          {orders.map((order, index) => (
            <li key={index} className="list-group-item">
              <h5>Order #{index + 1}</h5>
              <ul>
                {order.items.map((item, itemIndex) => (
                  <li key={itemIndex}>
                    {item.name} - ${item.price.toFixed(2)}
                  </li>
                ))}
              </ul>
              <p><strong>Total: ${order.total.toFixed(2)}</strong></p>
            </li>
          ))}
        </ul>
      ) : (
        <div>
          <p>No previous orders found.</p>
          <p><Link to="/products">Browse products</Link> and place your first order!</p>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
