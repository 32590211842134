import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, getDocs, doc, setDoc, deleteDoc, writeBatch } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import Modal from 'react-modal';

const AdminPopupManagement = () => {
  const [popups, setPopups] = useState([]);
  const [activePopup, setActivePopup] = useState(null);
  const [newPopup, setNewPopup] = useState({ id: '', title: '', description: '', imageUrl: '', active: false });
  const [imageFile, setImageFile] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const firestore = getFirestore();
  const storage = getStorage();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchPopups = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'popups'));
      const popupList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPopups(popupList);

      const activePopupDoc = popupList.find(popup => popup.active);
      if (activePopupDoc) {
        setActivePopup(activePopupDoc.id);
      }
    };

    fetchPopups();
  }, [firestore]);

  const handleSetActivePopup = async (id) => {
    const batch = writeBatch(firestore);

    const currentActivePopup = popups.find(popup => popup.active);
    if (currentActivePopup) {
      batch.update(doc(firestore, 'popups', currentActivePopup.id), { active: false });
    }

    batch.update(doc(firestore, 'popups', id), { active: true });

    await batch.commit();
    setActivePopup(id);

    const updatedPopups = await getDocs(collection(firestore, 'popups'));
    setPopups(updatedPopups.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleAddOrUpdatePopup = async () => {
    let imageUrl = newPopup.imageUrl;
    if (imageFile) {
      const storageRef = ref(storage, `popups/${imageFile.name}`);
      await uploadBytes(storageRef, imageFile);
      imageUrl = await getDownloadURL(storageRef);
    }

    const popupId = newPopup.id || new Date().toISOString();
    const popup = { ...newPopup, id: popupId, imageUrl, active: false };
    await setDoc(doc(firestore, 'popups', popupId), popup);

    setNewPopup({ id: '', title: '', description: '', imageUrl: '', active: false });
    setImageFile(null);
    fileInputRef.current.value = null;  // Clear the file input
    setModalIsOpen(false);

    const updatedPopups = await getDocs(collection(firestore, 'popups'));
    setPopups(updatedPopups.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleEditPopup = (popup) => {
    setNewPopup(popup);
    setModalIsOpen(true);
  };

  const handleDeletePopup = async (id) => {
    await deleteDoc(doc(firestore, 'popups', id));

    const popup = popups.find(popup => popup.id === id);
    if (popup && popup.imageUrl) {
      const imageRef = ref(storage, popup.imageUrl);
      await deleteObject(imageRef);
    }

    const updatedPopups = await getDocs(collection(firestore, 'popups'));
    setPopups(updatedPopups.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPopup({ id: '', title: '', description: '', imageUrl: '', active: false });
    setImageFile(null);
    fileInputRef.current.value = null;  // Clear the file input
  };

  return (
    <div>
      <h2>Admin: Popup Management</h2>
      <div>
        <h3>Active Popup</h3>
        {popups.filter(popup => popup.id === activePopup).map(popup => (
          <div key={popup.id}>
            <h4>{popup.title}</h4>
            <button onClick={() => handleSetActivePopup(popup.id)}>
              Active
            </button>
            <button onClick={() => handleEditPopup(popup)}>Edit</button>
            <button onClick={() => handleDeletePopup(popup.id)}>Delete</button>
          </div>
        ))}
      </div>
      <div>
        <h3>All Popups</h3>
        {popups.filter(popup => popup.id !== activePopup).map(popup => (
          <div key={popup.id}>
            <h4>{popup.title}</h4>
            <button onClick={() => handleSetActivePopup(popup.id)}>
              Set as Active
            </button>
            <button onClick={() => handleEditPopup(popup)}>Edit</button>
            <button onClick={() => handleDeletePopup(popup.id)}>Delete</button>
          </div>
        ))}
      </div>
      <div>
        <h3>Add or Update Popup</h3>
        <input
          type="text"
          value={newPopup.title}
          onChange={(e) => setNewPopup({ ...newPopup, title: e.target.value })}
          placeholder="Title"
        />
        <input
          type="file"
          ref={fileInputRef}
          onChange={(e) => setImageFile(e.target.files[0])}
        />
        <textarea
          value={newPopup.description}
          onChange={(e) => setNewPopup({ ...newPopup, description: e.target.value })}
          placeholder="Description"
        />
        <button onClick={handleAddOrUpdatePopup}>
          {newPopup.id ? 'Update Popup' : 'Save Popup'}
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Popup"
      >
        <h2>Edit Popup</h2>
        <form>
          <input
            type="text"
            value={newPopup.title}
            onChange={(e) => setNewPopup({ ...newPopup, title: e.target.value })}
            placeholder="Title"
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={(e) => setImageFile(e.target.files[0])}
          />
          <textarea
            value={newPopup.description}
            onChange={(e) => setNewPopup({ ...newPopup, description: e.target.value })}
            placeholder="Description"
          />
          <button type="button" onClick={handleAddOrUpdatePopup}>Update Popup</button>
          <button type="button" onClick={closeModal}>Close</button>
        </form>
      </Modal>
    </div>
  );
};

export default AdminPopupManagement;
