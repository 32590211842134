// src/components/Wishlist.js
import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { saveWishlist, loadWishlist, getProducts } from '../services/firestoreService';

const Wishlist = () => {
  const [products, setProducts] = useState([]);
  const [wishlist, setWishlist] = useState([]);

  useEffect(() => {
    const fetchWishlist = async () => {
      if (auth.currentUser) {
        try {
          const wishlistData = await loadWishlist();
          setWishlist(wishlistData);
        } catch (err) {
          console.error('Failed to load wishlist:', err);
        }
      }
    };

    const fetchProducts = async () => {
      try {
        const productsSnapshot = await getProducts();
        const productsList = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProducts(productsList);
      } catch (err) {
        console.error('Failed to fetch products:', err);
      }
    };

    fetchWishlist();
    fetchProducts();
  }, []);

  const addToWishlist = async (product) => {
    const updatedWishlist = [...wishlist, product];
    setWishlist(updatedWishlist);
    if (auth.currentUser) {
      try {
        await saveWishlist(updatedWishlist);
      } catch (err) {
        console.error('Failed to save wishlist:', err);
      }
    }
  };

  const removeFromWishlist = async (index) => {
    const updatedWishlist = wishlist.filter((_, i) => i !== index);
    setWishlist(updatedWishlist);
    if (auth.currentUser) {
      try {
        await saveWishlist(updatedWishlist);
      } catch (err) {
        console.error('Failed to save wishlist:', err);
      }
    }
  };

  return (
    <div className="container">
      <h2 className="my-4">Wishlist</h2>
      <ul className="list-group">
        {wishlist.map((item, index) => (
          <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
            {item.name} - ${item.price.toFixed(2)}
            <button onClick={() => removeFromWishlist(index)} className="btn btn-danger btn-sm">Remove</button>
          </li>
        ))}
      </ul>
      <div className="my-4">
        <h3>Add Products to Wishlist</h3>
        <div className="row">
          {products.map(product => (
            <div key={product.id} className="col-md-4">
              <div className="card mb-4">
                <img src={product.imageUrl} alt={product.name} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title">{product.name}</h5>
                  <p className="card-text">{product.description}</p>
                  <p className="card-text"><strong>${product.price.toFixed(2)}</strong></p>
                  <button onClick={() => addToWishlist(product)} className="btn btn-primary">Add to Wishlist</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
