// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBBIFcVbWuI2NuGk9228yJbVnKHyHGcwLI",
  authDomain: "armonydb-817d3.firebaseapp.com",
  projectId: "armonydb-817d3",
  storageBucket: "armonydb-817d3.appspot.com",
  messagingSenderId: "935358315624",
  appId: "1:935358315624:web:40ce4aac72aa37dad28fdd"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore, storage };
