// src/components/OrderConfirmation.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const OrderConfirmation = () => {
  const navigate = useNavigate();

  const handleBackToProducts = () => {
    navigate('/products');
  };

  return (
    <div>
      <h2>Order Confirmation</h2>
      <p>Thank you for your order! Your order has been placed successfully.</p>
      <button onClick={handleBackToProducts}>Back to Products</button>
    </div>
  );
};

export default OrderConfirmation;
