import { firestore, storage, auth } from '../firebase';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc, setDoc, getDoc, query, where, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const productsCollection = collection(firestore, 'products');
const usersCollection = collection(firestore, 'users');
const ordersCollection = collection(firestore, 'orders');
const adminsCollection = collection(firestore, 'admins');
const categoriesCollection = collection(firestore, 'categories');
const homepageContentCollection = collection(firestore, 'homepageContent');

export const addProduct = async (product, imageFile) => {
  let imageUrl = '';
  if (imageFile) {
    const storageRef = ref(storage, `products/${imageFile.name}`);
    try {
      const snapshot = await uploadBytes(storageRef, imageFile);
      imageUrl = await getDownloadURL(snapshot.ref);
    } catch (error) {
      throw error;
    }
  }
  const productWithMetadata = {
    ...product,
    imageUrl,
    createdAt: serverTimestamp(),
    orderCount: 0,
  };
  return await addDoc(productsCollection, productWithMetadata);
};

export const getProduct = async (id) => {
  const productDoc = doc(firestore, 'products', id);
  const productSnapshot = await getDoc(productDoc);
  if (productSnapshot.exists()) {
    return productSnapshot.data();
  } else {
    throw new Error('Product not found');
  }
};

export const getProducts = async () => {
  return await getDocs(productsCollection);
};

export const getProductsByCategory = async (categoryName) => {
  const q = query(productsCollection, where("category", "==", categoryName), where("disabled", "==", false));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const updateProduct = async (id, updatedProduct, imageFile) => {
  let imageUrl = updatedProduct.imageUrl;
  if (imageFile) {
    const storageRef = ref(storage, `products/${imageFile.name}`);
    try {
      const snapshot = await uploadBytes(storageRef, imageFile);
      imageUrl = await getDownloadURL(snapshot.ref);
    } catch (error) {
      throw error;
    }
  }
  const productWithoutFile = { ...updatedProduct, imageUrl };
  delete productWithoutFile.imageFile;
  return await updateDoc(doc(firestore, 'products', id), productWithoutFile);
};

export const deleteProduct = async (id) => {
  return await deleteDoc(doc(firestore, 'products', id));
};

export const saveCart = async (cart) => {
  const user = auth.currentUser;
  if (user) {
    const userDocRef = doc(usersCollection, user.uid);
    await updateDoc(userDocRef, { cart });
  }
};

export const loadCart = async () => {
  const user = auth.currentUser;
  if (user) {
    const userDoc = await getDoc(doc(usersCollection, user.uid));
    if (userDoc.exists()) {
      return userDoc.data().cart || [];
    }
  }
  return [];
};

export const updateProductStock = async (productId, quantity) => {
  const productDoc = doc(firestore, 'products', productId);
  const productSnapshot = await getDoc(productDoc);

  if (productSnapshot.exists()) {
    const currentStock = productSnapshot.data().stock;
    const newStock = currentStock - quantity;

    await updateDoc(productDoc, {
      stock: newStock
    });

    return newStock;
  } else {
    throw new Error('Product not found');
  }
};

export const saveWishlist = async (wishlist) => {
  const user = auth.currentUser;
  if (user) {
    const userDocRef = doc(usersCollection, user.uid);
    await updateDoc(userDocRef, { wishlist });
  }
};

export const loadWishlist = async () => {
  const user = auth.currentUser;
  if (user) {
    const userDoc = await getDoc(doc(usersCollection, user.uid));
    if (userDoc.exists()) {
      return userDoc.data().wishlist || [];
    }
  }
  return [];
};

export const saveOrder = async (order) => {
  const orderDoc = await addDoc(ordersCollection, order);

  // Update order count for each product
  for (let item of order.items) {
    const productDoc = doc(firestore, 'products', item.id);
    const productSnapshot = await getDoc(productDoc);
    if (productSnapshot.exists()) {
      const currentOrderCount = productSnapshot.data().orderCount || 0;
      await updateDoc(productDoc, {
        orderCount: currentOrderCount + item.quantity
      });
    }
  }

  return orderDoc;
};

export const loadOrderHistory = async () => {
  const user = auth.currentUser;
  if (user) {
    const ordersSnapshot = await getDocs(ordersCollection);
    const ordersList = ordersSnapshot.docs
      .filter(doc => doc.data().userId === user.uid)
      .map(doc => doc.data());
    return ordersList;
  }
  return [];
};

export const addReview = async (productId, review) => {
  const user = auth.currentUser;
  if (user) {
    const userDoc = await getDoc(doc(usersCollection, user.uid));
    if (userDoc.exists()) {
      const userName = userDoc.data().name;
      const reviewWithUser = { ...review, userName };
      const reviewDoc = doc(collection(firestore, 'products', productId, 'reviews'), new Date().toISOString());
      return await setDoc(reviewDoc, reviewWithUser);
    }
  }
  throw new Error('User not authenticated or user data not found');
};

export const getReviews = async (productId) => {
  const reviewsSnapshot = await getDocs(collection(firestore, 'products', productId, 'reviews'));
  return reviewsSnapshot.docs.map(doc => doc.data());
};

export const addAdmin = async (email) => {
  return await addDoc(adminsCollection, { email });
};

export const isAdmin = async (email) => {
  const q = query(adminsCollection, where("email", "==", email));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
};

export const getAdmins = async () => {
  const adminsSnapshot = await getDocs(adminsCollection);
  return adminsSnapshot.docs.map(doc => doc.data());
};

export const updateCartItemQuantity = async (productId, quantity) => {
  const user = auth.currentUser;
  if (user) {
    const userDocRef = doc(firestore, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const cart = userDoc.data().cart || [];
      const updatedCart = cart.map(item =>
        item.id === productId ? { ...item, quantity } : item
      );
      await updateDoc(userDocRef, { cart: updatedCart });
      return updatedCart;
    }
  }
  return [];
};

// New category-related functions

export const addCategory = async (categoryName) => {
  return await addDoc(categoriesCollection, { name: categoryName });
};

export const getCategories = async () => {
  return await getDocs(categoriesCollection);
};

export const deleteCategory = async (id) => {
  return await deleteDoc(doc(firestore, 'categories', id));
};

// Homepage content-related functions

export const getHomepageContent = async () => {
  return await getDocs(homepageContentCollection);
};

export const addHomepageContent = async (content) => {
  return await addDoc(homepageContentCollection, content);
};

export const updateHomepageContent = async (id, updatedContent) => {
  return await updateDoc(doc(firestore, 'homepageContent', id), updatedContent);
};

export const deleteHomepageContent = async (id) => {
  return await deleteDoc(doc(firestore, 'homepageContent', id));
};

export const setCurrentHomepageContent = async (id) => {
  return await setDoc(doc(firestore, 'homepageContent', 'current'), { activeContent: id });
};

export const getBestSellingProducts = async () => {
  const q = query(productsCollection, where("orderCount", ">", 0));
  const querySnapshot = await getDocs(q);
  const products = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

  // Sort products by order count in descending order and return the top 5
  return products.sort((a, b) => b.orderCount - a.orderCount).slice(0, 5);
};

export const getRecentlyAddedProducts = async () => {
  const now = new Date();
  const lastMonth = new Date(now.setMonth(now.getMonth() - 1));

  const q = query(productsCollection, where("createdAt", ">=", lastMonth));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};
