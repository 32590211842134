import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomerRegister from './components/CustomerRegister';
import CustomerLogin from './components/CustomerLogin';
import ProductCatalog from './components/ProductCatalog';
import AdminProducts from './components/AdminProducts';
import CustomerProfile from './components/CustomerProfile';
import Checkout from './components/Checkout';
import OrderConfirmation from './components/OrderConfirmation';
import Wishlist from './components/Wishlist';
import OrderHistory from './components/OrderHistory';
import ProductReview from './components/ProductReview';
import ProductDetails from './components/ProductDetails';
import AdminManagement from './components/AdminManagement';
import AdminHomepageContent from './components/AdminHomepageContent';
import AdminPopupManagement from './components/AdminPopupManagement';
import Homepage from './components/Homepage';
import NavBar from './components/NavBar';
import ForgotPassword from './components/ForgotPassword';
import AuthAction from './components/AuthAction';
import ResetPassword from './components/ResetPassword';
import CartSidebar from './components/CartSidebar';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import ReturnCancellationPolicy from './components/ReturnCancellationPolicy';
import ShippingInformation from './components/ShippingInformation';
import TermsConditions from './components/TermsConditions';
import WhatsAppButton from './components/WhatsAppButton'; // Import the WhatsAppButton component
import { auth, firestore } from './firebase';
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { CartProvider } from './contexts/CartContext';
import './App.css';

const AdminRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdmin = async (user) => {
      if (user) {
        const q = query(collection(getFirestore(), 'admins'), where('email', '==', user.email));
        const querySnapshot = await getDocs(q);
        setIsAdmin(!querySnapshot.empty);
      }
      setLoading(false);
    };

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setLoading(true);
        checkAdmin(user);
      } else {
        setIsAdmin(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAdmin ? children : <Navigate to="/" />;
};

function App() {
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(true);
  const [isCartSidebarOpen, setIsCartSidebarOpen] = useState(false);

  const toggleCartSidebar = () => {
    setIsCartSidebarOpen(!isCartSidebarOpen);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async currentUser => {
      setUser(currentUser);
      if (currentUser) {
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserName(userDoc.data().name);
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const adjustContentPadding = () => {
      const navbar = document.querySelector('.navbar');
      if (navbar) {
        const navbarHeight = navbar.offsetHeight;
        const contentElement = document.querySelector('.App-content');
        contentElement.style.paddingTop = `${navbarHeight}px`;
      }
    };

    // Adjust padding on initial load
    adjustContentPadding();

    // Adjust padding on window resize
    window.addEventListener('resize', adjustContentPadding);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', adjustContentPadding);
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <CartProvider>
      <Router>
        <div className="App">
          <ToastContainer />
          <header className="App-header">
            <NavBar user={user} userName={userName} toggleCartSidebar={toggleCartSidebar} setIsCartSidebarOpen={setIsCartSidebarOpen} />
          </header>
          <div className="App-content">
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/home" element={<Homepage />} />
              <Route path="/customer/register" element={<CustomerRegister />} />
              <Route path="/customer/login" element={<CustomerLogin />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/products" element={<ProductCatalog />} />
              <Route path="/customer/profile" element={<CustomerProfile />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/confirmation" element={<OrderConfirmation />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/order-history" element={<OrderHistory />} />
              <Route path="/products/:productId/reviews" element={<ProductReview />} />
              <Route path="/products/:productId" element={<ProductDetails />} />
              <Route path="/auth/action" element={<AuthAction />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/return-cancellation-policy" element={<ReturnCancellationPolicy />} />
              <Route path="/shipping-information" element={<ShippingInformation />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route
                path="/admin/products"
                element={
                  <AdminRoute>
                    <AdminProducts />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/management"
                element={
                  <AdminRoute>
                    <AdminManagement />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/homepage-content"
                element={
                  <AdminRoute>
                    <AdminHomepageContent />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/popup-management"
                element={
                  <AdminRoute>
                    <AdminPopupManagement />
                  </AdminRoute>
                }
              />
            </Routes>
          </div>
          <CartSidebar isOpen={isCartSidebarOpen} toggleCartSidebar={toggleCartSidebar} />
          <Footer />
          <WhatsAppButton /> {/* Add WhatsApp button */}
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
