import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './Homepage.module.css';
import Modal from 'react-modal';

const Homepage = () => {
  const [homepageContent, setHomepageContent] = useState(null);
  const [sectionsProducts, setSectionsProducts] = useState({});
  const [activePopup, setActivePopup] = useState(null);
  const [popupModalIsOpen, setPopupModalIsOpen] = useState(false);
  const firestore = getFirestore();

  useEffect(() => {
    const fetchHomepageContent = async () => {
      const currentContentDoc = await getDoc(doc(firestore, 'homepageContent', 'current'));
      if (currentContentDoc.exists()) {
        const activeContentId = currentContentDoc.data().activeContent;
        if (activeContentId) {
          const activeContentDoc = await getDoc(doc(firestore, 'homepageContent', activeContentId));
          if (activeContentDoc.exists()) {
            const contentData = activeContentDoc.data();
            setHomepageContent(contentData);

            if (contentData.sections) {
              const productsData = {};

              for (let section of contentData.sections) {
                if (section.type === 'productList') {
                  let productsList = [];
                  switch (section.content.option) {
                    case 'bestSelling':
                      productsList = await getBestSellingProducts(firestore);
                      break;
                    case 'recentlyAdded':
                      productsList = await getRecentlyAddedProducts(firestore);
                      break;
                    default:
                      productsList = await getProductsByCategory(firestore, section.content.option);
                      break;
                  }
                  productsData[section.title] = productsList;
                }
              }

              setSectionsProducts(productsData);
            }
          }
        }
      }
    };

    const fetchActivePopup = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'popups'));
      const activePopupDoc = querySnapshot.docs.find(doc => doc.data().active);
      if (activePopupDoc) {
        setActivePopup(activePopupDoc.data());
        setPopupModalIsOpen(true);
      }
    };

    fetchHomepageContent();
    fetchActivePopup();
  }, [firestore]);

  const closePopupModal = () => {
    setPopupModalIsOpen(false);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div>
      {homepageContent && (
        <div>
          <div className={styles.heroSection}>
            <img src={homepageContent.heroImage} className={styles.heroImage} alt="Hero section" />
            <div className={styles.heroText}>
              {homepageContent.heroText}
            </div>
          </div>
          <div className={styles.homepageContent}>
            <h1>{homepageContent.title}</h1>
            <p>{homepageContent.description}</p>

            {homepageContent.sections && homepageContent.sections.map((section, index) => (
              <div key={index} className={styles.section}>
                <h2>{section.title}</h2>
                {section.type === 'productList' && (
                  <div className={styles.carouselContainer}>
                    <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000}>
                      {(sectionsProducts[section.title] || []).map(product => (
                        <div key={product.id} className={styles.carouselItem}>
                          <img src={product.imageUrl} alt={product.name} className={styles.carouselImage} />
                          <div className={styles.carouselCaption}>
                            <h5>{product.name}</h5>
                            <p>{product.description}</p>
                            <p><strong>{formatCurrency(product.price)}</strong></p>
                            <p>Category: {product.category}</p>
                            <p>Stock: {product.stock > 0 ? product.stock : 'Out of Stock'}</p>
                            <Link to={`/products/${product.id}`}>
                              <button className="btn btn-secondary mt-2">View Product</button>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                    <div className={styles.seeAllContainer}>
                      <Link to="/products">
                        <button className="btn btn-primary mt-3">See All Products</button>
                      </Link>
                    </div>
                  </div>
                )}
                {section.type === 'normal' && (
                  <div>
                    <img src={section.content.image} className={styles.sectionImage} alt={section.title} />
                    <p>{section.content.text}</p>
                  </div>
                )}
                {section.type === 'onImage' && (
                  <div className={styles.onImageSection}>
                    <img src={section.content.image} className={styles.onImageSectionImage} alt={section.title} />
                    <div
                      className={styles.onImageSectionText}
                      style={{ color: section.content.textColor }}
                    >
                      {section.content.text}
                    </div>
                  </div>
                )}
                {section.type === 'carousel' && (
                  <div className={styles.carouselContainer}>
                    <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000}>
                      {section.content.images && section.content.images.map((image, idx) => (
                        <div key={idx} className={styles.carouselItem}>
                          <img src={image} className={styles.carouselImage} alt={`carousel-${idx}`} />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                )}
                {/* Render other section types here */}
              </div>
            ))}
          </div>
        </div>
      )}
      {activePopup && (
        <Modal
          isOpen={popupModalIsOpen}
          onRequestClose={closePopupModal}
          contentLabel="Popup Modal"
          className={styles.modal}
          overlayClassName={styles.overlay}
        >
          <h2>{activePopup.title}</h2>
          {activePopup.imageUrl && (
            <img src={activePopup.imageUrl} className={styles.popupImage} alt={activePopup.title} />
          )}
          <p>{activePopup.description}</p>
          <button onClick={closePopupModal} className="btn btn-primary">Close</button>
        </Modal>
      )}
    </div>
  );
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const getBestSellingProducts = async (firestore) => {
  const productsCollection = collection(firestore, 'products');
  const productsQuery = query(productsCollection, where('orderCount', '>', 0));
  const querySnapshot = await getDocs(productsQuery);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const getRecentlyAddedProducts = async (firestore) => {
  const productsCollection = collection(firestore, 'products');
  const productsQuery = query(productsCollection, where('createdAt', '<=', new Date()));
  const querySnapshot = await getDocs(productsQuery);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const getProductsByCategory = async (firestore, category) => {
  const productsCollection = collection(firestore, 'products');
  const productsQuery = query(productsCollection, where('category', '==', category));
  const querySnapshot = await getDocs(productsQuery);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export default Homepage;
