import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, getDocs, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import Modal from 'react-modal';
import styles from './AdminHomepageContent.module.css';

const AdminHomepageContent = () => {
  const [contentList, setContentList] = useState([]);
  const [activeContent, setActiveContent] = useState('');
  const [newContent, setNewContent] = useState({ id: '', title: '', description: '', heroImage: '', heroText: '', sections: [] });
  const [categories, setCategories] = useState([]);
  const [heroImageFile, setHeroImageFile] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const firestore = getFirestore();
  const storage = getStorage();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchContentList = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'homepageContent'));
      const contents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const filteredContents = contents.filter(content => content.id !== 'current');
      setContentList(filteredContents);

      const currentContentDoc = await getDoc(doc(firestore, 'homepageContent', 'current'));
      if (currentContentDoc.exists()) {
        const activeContentId = currentContentDoc.data().activeContent;
        if (activeContentId) {
          const activeContentDoc = await getDoc(doc(firestore, 'homepageContent', activeContentId));
          if (activeContentDoc.exists()) {
            setActiveContent(activeContentId);
          } else {
            console.warn(`Active content document with ID ${activeContentId} does not exist.`);
          }
        }
      }

      const categorySnapshot = await getDocs(collection(firestore, 'categories'));
      const categoriesList = categorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCategories(categoriesList);
    };

    fetchContentList();
  }, [firestore]);

  const handleSetActiveContent = async (id) => {
    const activeContentDoc = await getDoc(doc(firestore, 'homepageContent', id));
    if (activeContentDoc.exists()) {
      await setDoc(doc(firestore, 'homepageContent', 'current'), { activeContent: id });
      setActiveContent(id);
    } else {
      console.error(`Document with ID ${id} does not exist.`);
    }
  };

  const handleAddOrUpdateContent = async () => {
    let heroImageUrl = newContent.heroImage;
    if (heroImageFile) {
      const storageRef = ref(storage, `homepage/${heroImageFile.name}`);
      await uploadBytes(storageRef, heroImageFile);
      heroImageUrl = await getDownloadURL(storageRef);
    }

    if (!newContent.title || !newContent.description) {
      alert("Title and Description are required.");
      return;
    }

    const contentId = newContent.id || new Date().toISOString();
    const content = { ...newContent, id: contentId, heroImage: heroImageUrl };
    const docRef = doc(firestore, 'homepageContent', contentId);
    await setDoc(docRef, content);

    setNewContent({ id: '', title: '', description: '', heroImage: '', heroText: '', sections: [] });
    setHeroImageFile(null);
    fileInputRef.current.value = null;

    const querySnapshot = await getDocs(collection(firestore, 'homepageContent'));
    const contents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setContentList(contents.filter(content => content.id !== 'current'));
    closeModal();
  };

  const handleEditContent = (content) => {
    setNewContent(content);
    setModalIsOpen(true);
  };

  const handleDeleteContent = async (id) => {
    await deleteDoc(doc(firestore, 'homepageContent', id));

    const content = contentList.find(content => content.id === id);
    if (content && content.heroImage) {
      const imageRef = ref(storage, content.heroImage);
      await deleteObject(imageRef);
    }

    const querySnapshot = await getDocs(collection(firestore, 'homepageContent'));
    const contents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setContentList(contents.filter(content => content.id !== 'current'));
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewContent({ id: '', title: '', description: '', heroImage: '', heroText: '', sections: [] });
    setHeroImageFile(null);
    fileInputRef.current.value = null;
  };

  const addSection = (e) => {
    e.preventDefault();
    setNewContent((prevContent) => ({
      ...prevContent,
      sections: [...prevContent.sections, { title: '', type: 'normal', content: {} }]
    }));
  };

  const updateSection = (index, updatedSection) => {
    const updatedSections = newContent.sections.map((section, idx) => (idx === index ? updatedSection : section));
    setNewContent((prevContent) => ({
      ...prevContent,
      sections: updatedSections
    }));
  };

  const removeSection = (index) => {
    setNewContent(prevContent => {
      const updatedSections = prevContent.sections.filter((_, idx) => idx !== index);
      return {
        ...prevContent,
        sections: updatedSections
      };
    });
  };

  const handleSectionImageUpload = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `homepageSections/${file.name}`);
      uploadBytes(storageRef, file).then(async (snapshot) => {
        const downloadURL = await getDownloadURL(snapshot.ref);
        const updatedSection = {
          ...newContent.sections[index],
          content: {
            ...newContent.sections[index].content,
            image: downloadURL
          }
        };
        updateSection(index, updatedSection);
      });
    }
  };

  const handleSectionCarouselImagesUpload = (index, e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const uploadPromises = [];
      const imageUrls = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const storageRef = ref(storage, `homepageSections/${file.name}`);
        const uploadTask = uploadBytes(storageRef, file)
          .then(snapshot => getDownloadURL(snapshot.ref))
          .then(url => imageUrls.push(url));
        uploadPromises.push(uploadTask);
      }
      Promise.all(uploadPromises).then(() => {
        const updatedSection = {
          ...newContent.sections[index],
          content: {
            ...newContent.sections[index].content,
            images: imageUrls
          }
        };
        updateSection(index, updatedSection);
      });
    }
  };

  return (
    <div className={styles.adminHomepageContent}>
      <h2>Admin: Homepage Content Management</h2>
      <div>
        <h3>Current Active Content</h3>
        {contentList.filter(content => content.id === activeContent && content.title).map(content => (
          <div key={content.id}>
            <h4>{content.title}</h4>
            <button onClick={() => handleSetActiveContent(content.id)}>
              Active
            </button>
            <button onClick={() => handleEditContent(content)}>Edit</button>
            <button onClick={() => handleDeleteContent(content.id)}>Delete</button>
          </div>
        ))}
      </div>
      <div>
        <h3>All Contents</h3>
        {contentList.filter(content => content.id !== activeContent && content.title).map(content => (
          <div key={content.id}>
            <h4>{content.title}</h4>
            <button onClick={() => handleSetActiveContent(content.id)}>
              Set as Active
            </button>
            <button onClick={() => handleEditContent(content)}>Edit</button>
            <button onClick={() => handleDeleteContent(content.id)}>Delete</button>
          </div>
        ))}
      </div>
      <div>
        <h3>Add or Update Content</h3>
        <input
          type="text"
          value={newContent.title}
          onChange={(e) => setNewContent({ ...newContent, title: e.target.value })}
          placeholder="Title"
        />
        <input
          type="file"
          ref={fileInputRef}
          onChange={(e) => setHeroImageFile(e.target.files[0])}
        />
        <textarea
          value={newContent.description}
          onChange={(e) => setNewContent({ ...newContent, description: e.target.value })}
          placeholder="Description"
        />
        <input
          type="text"
          value={newContent.heroText}
          onChange={(e) => setNewContent({ ...newContent, heroText: e.target.value })}
          placeholder="Hero Text"
        />
        <h3>Sections</h3>
        {newContent.sections.map((section, index) => (
          <div key={index} className={styles.section}>
            <input
              type="text"
              value={section.title}
              onChange={(e) => updateSection(index, { ...section, title: e.target.value })}
              placeholder="Section Title"
            />
            <select
              value={section.type}
              onChange={(e) => updateSection(index, { ...section, type: e.target.value, content: {} })}
            >
              <option value="normal">Normal</option>
              <option value="onImage">On Image</option>
              <option value="carousel">Carousel</option>
              <option value="productList">Product List</option>
            </select>
            {section.type === 'normal' && (
              <div>
                <input
                  type="file"
                  onChange={(e) => handleSectionImageUpload(index, e)}
                />
                <textarea
                  value={section.content.text || ''}
                  onChange={(e) => updateSection(index, { ...section, content: { ...section.content, text: e.target.value } })}
                  placeholder="Text"
                />
              </div>
            )}
            {section.type === 'onImage' && (
              <div>
                <input
                  type="file"
                  onChange={(e) => handleSectionImageUpload(index, e)}
                />
                <textarea
                  value={section.content.text || ''}
                  onChange={(e) => updateSection(index, { ...section, content: { ...section.content, text: e.target.value } })}
                  placeholder="Text"
                />
                <input
                  type="color"
                  value={section.content.textColor || '#000000'}
                  onChange={(e) => updateSection(index, { ...section, content: { ...section.content, textColor: e.target.value } })}
                />
              </div>
            )}
            {section.type === 'carousel' && (
              <div>
                <input
                  type="file"
                  multiple
                  onChange={(e) => handleSectionCarouselImagesUpload(index, e)}
                />
              </div>
            )}
            {section.type === 'productList' && (
              <div>
                <select
                  value={section.content.option || ''}
                  onChange={(e) => updateSection(index, { ...section, content: { ...section.content, option: e.target.value } })}
                >
                  <option value="">Select Option</option>
                  <option value="bestSelling">Best Selling</option>
                  <option value="recentlyAdded">Recently Added</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.name}>{category.name}</option>
                  ))}
                </select>
              </div>
            )}
            <button onClick={(e) => { e.preventDefault(); removeSection(index); }}>Remove Section</button>
          </div>
        ))}
        <button onClick={addSection}>Add Section</button>
        <button onClick={handleAddOrUpdateContent}>
          {newContent.id ? 'Update Content' : 'Save Content'}
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Homepage Content"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Edit Homepage Content</h2>
        <form>
          <input
            type="text"
            value={newContent.title}
            onChange={(e) => setNewContent({ ...newContent, title: e.target.value })}
            placeholder="Title"
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={(e) => setHeroImageFile(e.target.files[0])}
          />
          <textarea
            value={newContent.description}
            onChange={(e) => setNewContent({ ...newContent, description: e.target.value })}
            placeholder="Description"
          />
          <input
            type="text"
            value={newContent.heroText}
            onChange={(e) => setNewContent({ ...newContent, heroText: e.target.value })}
            placeholder="Hero Text"
          />
          <h3>Sections</h3>
          {newContent.sections.map((section, index) => (
            <div key={index} className={styles.section}>
              <input
                type="text"
                value={section.title}
                onChange={(e) => updateSection(index, { ...section, title: e.target.value })}
                placeholder="Section Title"
              />
              <select
                value={section.type}
                onChange={(e) => updateSection(index, { ...section, type: e.target.value, content: {} })}
              >
                <option value="normal">Normal</option>
                <option value="onImage">On Image</option>
                <option value="carousel">Carousel</option>
                <option value="productList">Product List</option>
              </select>
              {section.type === 'normal' && (
                <div>
                  <input
                    type="file"
                    onChange={(e) => handleSectionImageUpload(index, e)}
                  />
                  <textarea
                    value={section.content.text || ''}
                    onChange={(e) => updateSection(index, { ...section, content: { ...section.content, text: e.target.value } })}
                    placeholder="Text"
                  />
                </div>
              )}
              {section.type === 'onImage' && (
                <div>
                  <input
                    type="file"
                    onChange={(e) => handleSectionImageUpload(index, e)}
                  />
                  <textarea
                    value={section.content.text || ''}
                    onChange={(e) => updateSection(index, { ...section, content: { ...section.content, text: e.target.value } })}
                    placeholder="Text"
                  />
                  <input
                    type="color"
                    value={section.content.textColor || '#000000'}
                    onChange={(e) => updateSection(index, { ...section, content: { ...section.content, textColor: e.target.value } })}
                  />
                </div>
              )}
              {section.type === 'carousel' && (
                <div>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => handleSectionCarouselImagesUpload(index, e)}
                  />
                </div>
              )}
              {section.type === 'productList' && (
                <div>
                  <select
                    value={section.content.option || ''}
                    onChange={(e) => updateSection(index, { ...section, content: { ...section.content, option: e.target.value } })}
                  >
                    <option value="">Select Option</option>
                    <option value="bestSelling">Best Selling</option>
                    <option value="recentlyAdded">Recently Added</option>
                    {categories.map(category => (
                      <option key={category.id} value={category.name}>{category.name}</option>
                    ))}
                  </select>
                </div>
              )}
              <button onClick={(e) => { e.preventDefault(); removeSection(index); }}>Remove Section</button>
            </div>
          ))}
          <button onClick={addSection}>Add Section</button>
          <button type="button" onClick={handleAddOrUpdateContent}>Update Content</button>
          <button type="button" onClick={closeModal}>Close</button>
        </form>
      </Modal>
    </div>
  );
};

export default AdminHomepageContent;
