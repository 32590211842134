// src/components/AdminManagement.js
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const AdminManagement = () => {
  const [adminEmail, setAdminEmail] = useState('');
  const [admins, setAdmins] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const firestore = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const fetchAdmins = async () => {
      const q = collection(firestore, 'admins');
      const querySnapshot = await getDocs(q);
      const adminsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAdmins(adminsList);
    };

    const checkAdmin = async (user) => {
      if (user) {
        const q = collection(firestore, 'admins');
        const querySnapshot = await getDocs(q);
        const adminsList = querySnapshot.docs.map(doc => doc.data().email);
        setIsAdmin(adminsList.includes(user.email));
      }
    };

    fetchAdmins();

    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      checkAdmin(user);
    });
  }, [firestore, auth]);

  const handleAddAdmin = async () => {
    if (adminEmail) {
      await addDoc(collection(firestore, 'admins'), { email: adminEmail });
      setAdminEmail('');
      alert('Admin added successfully!');
      // Refresh the admin list
      const q = collection(firestore, 'admins');
      const querySnapshot = await getDocs(q);
      const adminsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAdmins(adminsList);
    }
  };

  const handleDeleteAdmin = async (id) => {
    await deleteDoc(doc(firestore, 'admins', id));
    alert('Admin removed successfully!');
    // Refresh the admin list
    const q = collection(firestore, 'admins');
    const querySnapshot = await getDocs(q);
    const adminsList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setAdmins(adminsList);
  };

  if (!isAdmin) {
    return <p>You do not have access to this page.</p>;
  }

  return (
    <div>
      <h2>Admin Management</h2>
      {currentUser && <p>Logged in as: {currentUser.email}</p>}
      <input
        type="email"
        value={adminEmail}
        onChange={(e) => setAdminEmail(e.target.value)}
        placeholder="Enter admin email"
      />
      <button onClick={handleAddAdmin}>Add Admin</button>
      <h3>Current Admins:</h3>
      <ul>
        {admins.map((admin) => (
          <li key={admin.id}>
            {admin.email}
            <button onClick={() => handleDeleteAdmin(admin.id)}>Remove</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminManagement;
