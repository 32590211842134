import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useNavigate, Link } from 'react-router-dom';
import styles from './CustomerProfile.module.css'; // Import the CSS module

const CustomerProfile = () => {
  const [user, setUser] = useState(null);
  const [profileInfo, setProfileInfo] = useState({
    email: '',
    name: '',
    lastName: ''
  });

  const [shippingInfo, setShippingInfo] = useState({
    recipientEmail: '',
    recipientName: '',
    recipientLastName: '',
    direccion: '',
    provincia: '',
    canton: '',
    distrito: '',
    codigoPostal: '',
    pais: 'Costa Rica',
    telefono: ''
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setProfileInfo({
            email: currentUser.email || '',
            name: data.name || '',
            lastName: data.lastName || ''
          });
          setShippingInfo({
            recipientEmail: currentUser.email || '',
            recipientName: data.name || '',
            recipientLastName: data.lastName || '',
            direccion: data.direccion || '',
            provincia: data.provincia || '',
            canton: data.canton || '',
            distrito: data.distrito || '',
            codigoPostal: data.codigoPostal || '',
            pais: 'Costa Rica',
            telefono: data.telefono || ''
          });
        }
      }
    };

    fetchUser();
  }, []);

  const handleProfileInputChange = (e) => {
    const { name, value } = e.target;
    setProfileInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleShippingInputChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleSaveProfile = async () => {
    if (user) {
      const userDocRef = doc(firestore, 'users', user.uid);
      await updateDoc(userDocRef, { ...profileInfo, ...shippingInfo });
      alert('Profile updated successfully.');
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (err) {
      console.error('Failed to sign out:', err);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Customer Profile</h2>
      {user ? (
        <div className={styles.profileContainer}>
          <div className={styles.card}>
            <h3 className={styles.cardTitle}>Account Information</h3>
            <form>
              <div className={styles.formGroup}>
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={profileInfo.email}
                  className={styles.input}
                  readOnly
                />
              </div>
              <div className={styles.formGroup}>
                <label>First Name</label>
                <input
                  type="text"
                  name="name"
                  value={profileInfo.name}
                  onChange={handleProfileInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={profileInfo.lastName}
                  onChange={handleProfileInputChange}
                  className={styles.input}
                  required
                />
              </div>
            </form>
          </div>

          <div className={styles.card}>
            <h3 className={styles.cardTitle}>Shipping Information</h3>
            <form>
              <div className={styles.formGroup}>
                <label>Recipient Email</label>
                <input
                  type="email"
                  name="recipientEmail"
                  value={shippingInfo.recipientEmail}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Recipient's First Name</label>
                <input
                  type="text"
                  name="recipientName"
                  value={shippingInfo.recipientName}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Recipient's Last Name</label>
                <input
                  type="text"
                  name="recipientLastName"
                  value={shippingInfo.recipientLastName}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Phone</label>
                <input
                  type="text"
                  name="telefono"
                  value={shippingInfo.telefono}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Address</label>
                <input
                  type="text"
                  name="direccion"
                  value={shippingInfo.direccion}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Province</label>
                <input
                  type="text"
                  name="provincia"
                  value={shippingInfo.provincia}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Canton</label>
                <input
                  type="text"
                  name="canton"
                  value={shippingInfo.canton}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>District</label>
                <input
                  type="text"
                  name="distrito"
                  value={shippingInfo.distrito}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Postal Code</label>
                <input
                  type="text"
                  name="codigoPostal"
                  value={shippingInfo.codigoPostal}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Country</label>
                <input
                  type="text"
                  name="pais"
                  value={shippingInfo.pais}
                  className={styles.input}
                  readOnly
                />
              </div>
              <button
                type="button"
                onClick={handleSaveProfile}
                className={styles.saveButton}
              >
                Save Profile
              </button>
            </form>
          </div>

          <button onClick={handleSignOut} className={styles.signOutButton}>
            Sign Out
          </button>
          <Link to="/order-history" className={styles.orderHistoryButton}>
            View Order History
          </Link>
        </div>
      ) : (
        <p>No user is logged in.</p>
      )}
    </div>
  );
};

export default CustomerProfile;
